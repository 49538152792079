
import { Vue, Component } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import GoogleAuthComponent from '@/components/landingAndRegistrationComponents/GoogleAuthComponent.vue';
import LandingFeatureGrid from '@/components/landingAndRegistrationComponents/LandingFeatureGrid.vue';

@Component({
  components: {
    BaseButton,
    GoogleAuthComponent,
    LandingFeatureGrid,
  },
})
export default class PricingPage extends Vue {
  pros: { title: string; description: string }[] = [
    {
      title: 'Cash flow forecast',
      description: 'Get real-time accurate forecast based on data from connected apps',
    },
    {
      title: 'KPIs',
      description: 'Monitor trends and compare different periods for the most important business metrics',
    },
    {
      title: 'Business Credit Score',
      description: 'Get your free business credit score powered by global business credit bureaus',
    },
    {
      title: 'Dashboard Pro',
      description: 'Make better decisions with powerful insights and custom business dashboard',
    },
    {
      title: '40+ Apps',
      description: 'Easily connect the tools you use every day to streamline your business',
    },
  ];
  packageFeatures: string[] = [
    'Small business dashboard',
    'Business credit score',
    'Access to funding',
    'Business valuation calculator',
  ];

  landingFeatures: { title: string; description: string; icon: string }[] = [
    {
      title: 'No credit card required',
      description: 'Moreover we offer incentives to the most loyal business that help us grow together!',
      icon: 'no-card',
    },
    {
      title: 'Free support',
      description: 'Our team is ready and waiting to help online and on the phone.',
      icon: 'support',
    },
    {
      title: 'Secure cloud storage',
      description: 'Bank-level security keeps your data stored safely in the cloud.',
      icon: 'secure-cloud',
    },
  ];

  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }
}
